const url = "https://nodepune-royalq.mobiloitte.io/api/v1";
const predictionURL = "https://nodepune-predictionroyalq.mobiloitte.io/api/v1"
// const url = "https://node.olympustrade.ai/api/v1";
// const url = "http://172.16.6.52:3040/api/v1";
// const url = "http://172.16.6.52:3038/api/v1";

export const socketNotification = "";
export const baseUrl = "";

const admin = `${url}/admin`;
const predictionAdmin = `${predictionURL}/admin`


const ApiConfig = {
  userLogin: `${admin}/login`,
  forgotPassword: `${admin}/forgotPasswordSendOTP`,
  verifyOTP: `${admin}/verifyForgotPasswordOTP`,
  resetPassword: `${admin}/resetPassword`,
  changepassword: `${admin}/changePassword`,
  loginOTPVerify: `${admin}/loginOTPVerify`,
  changePasswordSendOTP: `${admin}/changePasswordSendOTP`,
  changePasswordOTPVerify: `${admin}/changePasswordOTPVerify`,
  listUser: `${admin}/listUser`,
  userDetails: `${admin}/viewUserProfile`,
  blockUnblock: `${admin}/blockUnblockUser`,
  deleteUser: `${admin}/deleteUser`,
  getCountryCount: `${admin}/getCountryCount`,
  allBanner: `${url}/banner/get-all-banners`,
  addBanner: `${url}/banner/addBanner`,
  editBanner: `${url}/banner/editBanner`,
  deleteBanner: `${url}/banner/deleteBanner`,
  allBannerWeb: `${url}/banner/get-all-banners-of-web`,
  addBannerWeb: `${url}/banner/addbannerOfWeb`,
  editBannerWeb: `${url}/banner/editBannerofWeb`,
  deleteBannerWeb: `${url}/banner/deleteBannerofWeb`,
  editDiscount: `${admin}/updatePlanData`,
  announcementContentList: `${url}/announcement/announcementContentList`,
  viewannouncementContent: `${url}/announcement/viewannouncementContent`,
  editannouncementContent: `${url}/announcement/editannouncementContent`,
  deleteannouncement: `${url}/announcement/deleteannouncement`,
  addannouncementContent: `${url}/announcement/addannouncementContent`,
  discountManagementData: `${url}/buy/getTradingBotDetails`,
  dashboardCount: `${url}/admin/adminDashbordcount`,
  assetsData: `${admin}/assetsTransactionList`,
  referralData: `${admin}/referralList`,
  assetsTransactionList: `${url}/admin/assetsTransactionList`,
  approveWithdrawReq: `${url}/admin/approveWithdrawReq`,
  viewUserSubscriptionDetails: `${url}/admin/viewUserSubscriptionDetails`,
  viewUserLoginActivity: `${url}/admin/viewUserLoginActivity`,
  viewUserWalletTransactions: `${url}/admin/viewUserWalletTransactions`,
  viewUserConnectedExchanges: `${url}/admin/viewUserConnectedExchanges`,
  getListStatic: `${url}/static-content/staticContentList`,
  getSingleStaticContent: `${url}/static-content/getSingleStaticContent`,
  editStaticContent: `${url}/static-content/editStaticContent`,
  contactUsList: `${url}/admin/contact-us-list`,
  viewContactUs: `${url}/admin/viewContactUs`,
  newsLettersubscriberList: `${url}/admin/newsLettersubscriberList`,
  sendNotificationToAllUser: `${url}/admin/sendNotificationToAllUser`,
  adminDashbordcount: `${url}/admin/adminDashbordcount`,
  viewUserReferralDetails: `${url}/admin/viewUserReferralDetails`,
  articleManagement: `${url}/admin/listCategory`,
  addArticleCategory: `${url}/admin/createCategory`,
  editCategory: `${url}/admin/editCategory`,
  getArticleCategoryById: `${url}/admin/listArticleByCategoryId`,
  addArticle: `${url}/admin/addArticle`,
  blockUnblockArticle: `${url}/admin/blockUnblockArticle`,
  deleteCategory: `${url}/admin/deleteCategory`,
  viewArticle: `${url}/admin/viewArticle`,
  updateArticle: `${url}/admin/updateArticle`,
  getBatchlist: `${url}/admin/asste-transaction-withdraw-batch-list`,
  getBatchById: `${url}/admin/asste-transaction-withdraw-list`,
  listAdvertisment: `${url}/admin/listAdvertisment`,
  blockUnblockAdvertisment: `${url}/admin/blockUnblockAdvertisment`,
  addAdvertisment: `${url}/admin/addAdvertisment`,
  editAdvertisment: `${url}/admin/editAdvertisment`,
  // prediction managment
  listPrediction: `${predictionURL}/admin/listPrediction`,
  viewPredictionPool: `${predictionURL}/admin/viewPredictionPool`,
  viewPredictionPoolParticipants: `${predictionURL}/admin/viewPredictionPoolParticipants`,
  // support management
  feedbackList: `${url}/contactUs/feedbackList`,
  viewFeedback: `${url}/contactUs/viewFeedback`,
  respondFeedback: `${url}/contactUs/respondFeedback`,
  // strategy management
  listAIStrategy: `${url}/admin/listAIStrategy`,  
  getSingleAIStrategy: `${url}/admin/getSingleAIStrategy`,  


};

export default ApiConfig;

